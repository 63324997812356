import { EThemes } from '../types.ts';

export const updateMeta = (theme: EThemes) => {
  const existingThemeMeta = document.getElementById('theme-color');

  if (existingThemeMeta) {
    existingThemeMeta.remove();
  }

  const themeMeta = document.createElement('meta');

  themeMeta.id = 'theme-color';
  themeMeta.name = 'theme-color';
  themeMeta.content = theme === EThemes.LIGHT ? '#fff' : '#141414';

  document.head.appendChild(themeMeta);
};
