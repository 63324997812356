import { createStyles } from 'antd-style';

import { geekblue, green } from '@ant-design/colors';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  calendar: css`
    position: relative;
    padding-bottom: 150px;

    &.${prefixCls}-picker-calendar .${prefixCls}-picker-content th {
      height: 30px;
      vertical-align: top;
      border-bottom: 1px solid ${token.colorBorderSecondary};
    }

    & .${prefixCls}-picker-cell {
      padding: 0;
    }
    & .${prefixCls}-picker-cell .${prefixCls}-picker-cell-inner {
      padding: 4px 8px;
      border-radius: 100px;
      height: auto;
    }
    & .${prefixCls}-picker-cell .${prefixCls}-picker-cell-inner::before {
      border-radius: 100px !important;
    }
  `,
  select: css`
    width: 100%;
  `,
  header: css`
    margin-bottom: 12px;
  `,
  pagination: css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  green: css`
    background-color: ${green[0]};
    border: 1px solid ${green[1]};
  `,
  blue: css`
    background-color: ${geekblue[0]};
    border: 1px solid ${geekblue[1]};
  `,
  legend: css`
    position: absolute;
    bottom: 65px;
    left: 0;
  `,
  legendItem: css`
    text-align: center;
    width: 34px;
    padding: 4px 8px;
    border-radius: 100px;
  `,
}));
