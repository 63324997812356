import { QueryClient } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import {
  TEducationDisciplineSearch,
  TEducationMaterialSearch,
} from '@/api/endpoints/education';

import {
  DisciplineMaterialsPage,
  DisciplineMaterialsPending,
} from '@/pages/education/discipline-materials';

const educationSearchSchema = z.object({
  query: z.string().optional(),
  path: z.string().optional(),
});

export const Route = createFileRoute('/education_/$disciplineId/materials')({
  component: DisciplineMaterialsPage,
  pendingComponent: DisciplineMaterialsPending,
  loader: async ({
    deps,
    params,
  }: {
    context: { queryClient: QueryClient };
    deps: TEducationMaterialSearch;
    params: { disciplineId: string };
  }) => {
    if (!deps.path) {
      throw redirect({
        to: '/education/$disciplineId',
        params: { disciplineId: params.disciplineId },
      });
    }
  },
  validateSearch: zodValidator({
    schema: educationSearchSchema,
    input: 'output',
    output: 'input',
  }),
  loaderDeps: ({ search }: { search: TEducationDisciplineSearch }) => search,
});
