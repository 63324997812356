import { createFileRoute, notFound } from '@tanstack/react-router';
import { AxiosError } from 'axios';

import { educationQueries } from '@/api/endpoints/education';

import { NotFound } from '@/components/ui/not-found';

import {
  DisciplinePage,
  DisciplinePending,
} from '@/pages/education/discipline';

export const Route = createFileRoute('/education/$disciplineId')({
  component: DisciplinePage,
  pendingComponent: DisciplinePending,
  loader: async ({ params: { disciplineId }, context: { queryClient } }) => {
    return await queryClient
      .ensureQueryData(educationQueries.discipline(disciplineId))
      .catch((error: AxiosError) => {
        if (error.status === 404) {
          throw notFound();
        }
      });
  },
  notFoundComponent: () => (
    <NotFound
      title='404'
      subTitle='Запрашиваемая вами дисциплина не найдена'
      backLink='/education'
    />
  ),
});
