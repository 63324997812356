import { Flex, Layout, Skeleton } from 'antd';

export const ExternalResourcesPending = () => {
  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={24}>
        <Skeleton.Button shape='round' active />
        <Skeleton.Input size='large' active block />
        <Skeleton.Input size='large' active block />

        <Flex vertical gap={8}>
          {Array(8)
            .fill(0)
            .map((_, i) => (
              <Skeleton.Input key={i} size='large' active block />
            ))}
        </Flex>
      </Flex>
    </Layout.Content>
  );
};
