import { MenuItemType } from 'antd/lib/menu/interface';

import {
  AppstoreOutlined,
  CalendarOutlined,
  HomeOutlined,
  MessageFilled,
  SignatureOutlined,
} from '@ant-design/icons';

import { MenuLabel } from '../components/menu-label';

import { TModuleDTO } from '@/api/endpoints/auth';

export const getMenuItems = (
  modules: TModuleDTO[],
  iconStyle: string,
): MenuItemType[] => {
  const isModuleAvailable = (name: string) =>
    modules.some((module) => module.name === name);

  return [
    {
      key: '/',
      label: (
        <MenuLabel
          icon={<HomeOutlined className={iconStyle} />}
          description='Главная'
          link='/'
        />
      ),
    },
    {
      key: 'education',
      label: (
        <MenuLabel
          icon={<SignatureOutlined className={iconStyle} />}
          description='Обучение'
          link='/education'
          disabled={!isModuleAvailable('education')}
        />
      ),
      disabled: !isModuleAvailable('education'),
    },
    {
      key: 'assistant',
      label: (
        <MenuLabel
          icon={<MessageFilled className={iconStyle} />}
          description='Ассистент'
          link='/assistant'
          disabled={!isModuleAvailable('assistant')}
        />
      ),
      disabled: !isModuleAvailable('assistant'),
    },
    {
      key: 'schedule',
      label: (
        <MenuLabel
          icon={<CalendarOutlined className={iconStyle} />}
          description='Занятия'
          link='/schedule'
          disabled={!isModuleAvailable('schedule')}
        />
      ),
      disabled: !isModuleAvailable('schedule'),
    },
    {
      key: 'service',
      label: (
        <MenuLabel
          icon={<AppstoreOutlined className={iconStyle} />}
          description='Сервисы'
          link='/service'
        />
      ),
    },
  ];
};
