import { Divider, Flex, Tag, Typography } from 'antd';

import { IdcardFilled, ReadFilled } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { TScheduleLessonDTO } from '@/api/endpoints/schedule';

type TLessonContentProps = {
  lesson: TScheduleLessonDTO;
  isCard?: boolean;
};

export const LessonContent = ({
  lesson,
  isCard = true,
}: TLessonContentProps) => {
  const { styles, cx } = useStyles();

  return (
    <Flex
      vertical
      gap={12}
      className={cx({
        [styles.canceled]: lesson.cancel === 1,
      })}
    >
      <Flex align='center' justify='space-between'>
        <Typography.Text type='secondary'>{lesson.period}</Typography.Text>

        <Tag
          className={cx(styles.tag, {
            [styles.greenTag]: lesson.exam === 1,
            [styles.blueTag]: lesson.exam === 0,
            [styles.redTag]: lesson.cancel === 1,
          })}
          bordered={false}
        >
          {lesson.cancel === 1
            ? 'Отменено'
            : lesson.exam === 0
              ? 'Электив '
              : 'Занятие'}
        </Tag>
      </Flex>

      <Flex vertical gap={8}>
        {isCard ? (
          <Typography.Text strong className={styles.discipline}>
            {lesson.discipline}
          </Typography.Text>
        ) : (
          <>
            <Typography.Title level={3}>{lesson.discipline}</Typography.Title>
            {lesson.title && <Typography.Text>{lesson.title}</Typography.Text>}
            <Divider type='horizontal' />
          </>
        )}
        <Typography.Text type='secondary'>
          <ReadFilled className={styles.icon} />
          {lesson.teacher}
        </Typography.Text>
        {lesson.online ? (
          <Typography.Link
            href={lesson.route}
            onClick={(e) => {
              if (lesson.cancel === 1) {
                e.preventDefault();
              }
            }}
            target='_blank'
            rel='noreferrer'
            className={styles.externalLink}
          >
            <IdcardFilled className={styles.icon} />
            Онлайн
          </Typography.Link>
        ) : (
          <Typography.Text type='secondary'>
            <IdcardFilled className={styles.icon} />
            {lesson.route}
          </Typography.Text>
        )}
      </Flex>
    </Flex>
  );
};
