import { Flex, Layout, List, Skeleton } from 'antd';

export const ExternalArticlesPending = () => {
  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={24}>
        <Skeleton.Button shape='round' active />
        <Skeleton.Input size='large' active block />
        <Skeleton.Input size='large' active block />
        <List
          dataSource={Array(5).fill(0)}
          renderItem={() => (
            <List.Item>
              <Skeleton active title={false} paragraph={{ rows: 2 }} />
            </List.Item>
          )}
          itemLayout='horizontal'
        />
      </Flex>
    </Layout.Content>
  );
};
