import { createStyles } from 'antd-style';

import { geekblue, green } from '@ant-design/colors';

export const useStyles = createStyles(({ css, prefixCls }) => ({
  swiper: css`
    max-width: 100%;
    width: 100%;
  `,
  swiperSlide: css`
    height: max-content;
    width: max-content !important;
  `,
  day: css`
    width: 48px;
  `,
  btn: css`
    &.${prefixCls}-btn-lg {
      padding-inline: 13px !important;
      padding: 0;
    }
  `,
  greenBtn: css`
    background-color: ${green[0]};
    border-color: ${green[1]};
  `,
  blueBtn: css`
    background-color: ${geekblue[0]};
    border-color: ${geekblue[1]};
  `,
  skeletonHeader: css`
    height: 60px;
  `,
}));
