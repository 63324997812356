import { useMutation } from '@tanstack/react-query';
import { Avatar, Badge, Button, Drawer, Flex, Layout, Typography } from 'antd';
import { useState } from 'react';

import {
  BellOutlined,
  CloseOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { logout } from '@/api/endpoints/auth';

import { SelectProfile } from '@/components/widgets/select-profile';

import { EAuthStatus, useAuthStore } from '@/stores/auth';

export const Header = () => {
  const { styles, theme } = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const profileStore = useAuthStore.use;
  const updateCurrentProfile = profileStore.updateCurrentProfile();
  const updateToken = profileStore.updateToken();
  const updateStatus = profileStore.updateStatus();

  const user = useAuthStore.use.user();
  const onDrawerClose = () => setIsDrawerOpen(false);

  const mutation = useMutation({
    mutationKey: ['logout'],
    mutationFn: logout,
    onSuccess: () => {
      updateToken(null);
      updateStatus(EAuthStatus.UNAUTH);
      // eslint-disable-next-line
      // @ts-ignore
      updateCurrentProfile(null);
    },
  });

  return (
    <>
      <Layout.Header className={styles.header}>
        <Flex
          align='center'
          justify='space-between'
          className={styles.container}
        >
          <Flex
            align='center'
            gap={12}
            role='button'
            onClick={() => setIsDrawerOpen(true)}
          >
            <Avatar
              shape='circle'
              size={24}
              src={
                user.photoUrl &&
                'https://storage.yandexcloud.net/sso-dev/avatars/1732191947.jpg'
              }
              icon={<UserOutlined />}
            />
            <Typography.Text>{user.firstName}</Typography.Text>
          </Flex>

          <Button
            type='text'
            size='large'
            shape='circle'
            icon={
              <Badge offset={[-3, 3]} dot color={theme.colorError}>
                <BellOutlined />
              </Badge>
            }
          />
        </Flex>
      </Layout.Header>

      <Drawer
        open={isDrawerOpen}
        onClose={onDrawerClose}
        closeIcon={null}
        height='calc(var(--vh, 1vh) * 90)'
        title='Мой аккаунт'
        placement='bottom'
        rootClassName={styles.drawer}
        extra={
          <Button
            type='text'
            icon={<CloseOutlined />}
            onClick={onDrawerClose}
          />
        }
      >
        <Flex vertical gap={24}>
          <SelectProfile onSelect={onDrawerClose} />
          <Button
            color='danger'
            onClick={() => {
              onDrawerClose();
              mutation.mutateAsync();
            }}
            icon={<LogoutOutlined />}
            danger
            block
            size='large'
          >
            Выйти из аккаунта
          </Button>
        </Flex>
      </Drawer>
    </>
  );
};
