import dayjs from 'dayjs';

export const getMonthsPagination = (
  currentMonth: number,
  monthsNames: dayjs.MonthNames[],
) => {
  const previousMonthIndex = currentMonth === 0 ? 11 : currentMonth - 1;
  const nextMonthIndex = currentMonth === 11 ? 0 : currentMonth + 1;

  const previousMonthName = monthsNames[previousMonthIndex];
  const nextMonthName = monthsNames[nextMonthIndex];

  return { previousMonthName, nextMonthName };
};
