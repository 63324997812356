import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Avatar, Flex, Typography } from 'antd';

import { UserOutlined } from '@ant-design/icons';

import { ProfileItem } from './ProfileItem.tsx';
import { useStyles } from './styles.ts';

import { authByProfile, changeProfile } from '@/api/endpoints/auth';

import { EAuthStatus, useAuthStore } from '@/stores/auth';

type TSelectProfileProps = {
  onSelect?: () => void;
};

export const SelectProfile = ({ onSelect }: TSelectProfileProps) => {
  const { styles } = useStyles();

  const queryClient = useQueryClient();

  const profileStore = useAuthStore.use;
  const profilesList = profileStore.profilesList();
  const currentProfile = profileStore.currentProfile();
  const user = profileStore.user();
  const fullName = profileStore.getFullName()();
  const updateCurrentProfile = profileStore.updateCurrentProfile();
  const updateToken = profileStore.updateToken();
  const updateStatus = profileStore.updateStatus();

  const mutation = useMutation({
    mutationFn: (profileId: number) => {
      if (currentProfile) {
        return changeProfile(profileId);
      }

      return authByProfile(profileId, user.email);
    },
    onSuccess: (data) => {
      updateCurrentProfile(data.profile);
      updateToken(data.token);
      updateStatus(EAuthStatus.AUTH);
      queryClient.clear();
    },
  });

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={12} align='center'>
        <Avatar src={user.photoUrl} icon={<UserOutlined />} size={48} />

        <Flex vertical align='center'>
          <Typography.Text strong className={styles.textLarge}>
            {fullName}
          </Typography.Text>
          <Typography.Text type='secondary'>{user.email}</Typography.Text>
        </Flex>
      </Flex>

      {profilesList.length > 1 && (
        <Typography.Title level={3}>Выберите профиль</Typography.Title>
      )}

      <Flex vertical gap={8}>
        {profilesList.map((profile) => (
          <ProfileItem
            key={profile.id}
            profile={profile}
            onClick={async () => {
              if (profilesList.length > 1) {
                await mutation.mutateAsync(profile.id);

                if (onSelect) {
                  onSelect();
                }
              }
            }}
            loading={mutation.isPending}
            disabled={profile.id === currentProfile?.id}
          />
        ))}
      </Flex>
    </Flex>
  );
};
