import { Tag } from 'antd';

import { useStyles } from './styles.ts';

import { TAnnounceType } from '@/api/endpoints/announce';

export const AnnounceTypeTag = ({ type }: { type: TAnnounceType }) => {
  const { styles, cx } = useStyles();

  return (
    <Tag
      className={cx(styles.tag, {
        [styles.blue]: type === 'Разработка',
        [styles.magenta]: type === 'Разное',
        [styles.cyan]: type === 'Новости',
        [styles.orange]: type === 'Важно',
      })}
      bordered={false}
    >
      {type}
    </Tag>
  );
};
