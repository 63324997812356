import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  description: css`
    padding: 12px 16px;

    & .${prefixCls}-descriptions-row .${prefixCls}-descriptions-item {
      padding: 0;
    }
    & .${prefixCls}-descriptions-row .${prefixCls}-descriptions-item-label {
      padding-bottom: 4px;
    }
    &
      .${prefixCls}-descriptions-row:not(:last-child)
      .${prefixCls}-descriptions-item-content {
      padding-bottom: 12px;
    }
    & .${prefixCls}-divider {
      padding: 4px 0;
    }
  `,
  backgrounded: css`
    background-color: ${token.colorBgLayout};
    border-radius: ${token.borderRadius}px;
  `,
  skeletonNode: css`
    width: 100% !important;
    height: 130px;
    display: block !important;
  `,
  drawer: css`
    & .${prefixCls}-drawer-content {
      border-top-left-radius: ${token.borderRadius}px;
      border-top-right-radius: ${token.borderRadius}px;
    }
  `,
  paragraph: css`
    &.${prefixCls}-typography {
      margin-bottom: 0;
    }
  `,
}));
