import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import {
  ExternalArticlesPage,
  ExternalArticlesPending,
} from '@/pages/library/external-articles';

const searchSchema = z.object({
  query: z.string().optional(),
});

export const Route = createFileRoute('/library/external-articles')({
  component: ExternalArticlesPage,
  pendingComponent: ExternalArticlesPending,
  validateSearch: searchSchema,
});
