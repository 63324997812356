import { Flex, Layout, Skeleton } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 } from 'uuid';

import { useStyles } from './styles.ts';

import { WidgetSkeleton } from '@/components/ui/skeleton';

export const SchedulePending = () => {
  const { styles, cx } = useStyles();

  return (
    <Layout.Content className='content '>
      <Flex vertical gap={24}>
        <Flex
          align='center'
          justify='space-between'
          className={cx(styles.skeletonHeader, 'p-x')}
        >
          <Skeleton.Input active />
          <Skeleton.Avatar shape='square' size='large' active />
        </Flex>

        <Swiper
          grabCursor
          spaceBetween={4}
          slidesPerView='auto'
          className={cx(styles.swiper, 'p-x')}
          initialSlide={4}
        >
          {Array(8)
            .fill(0)
            .map(() => (
              <SwiperSlide className={styles.swiperSlide} key={v4()}>
                <Skeleton.Avatar active shape='circle' size={48} />
              </SwiperSlide>
            ))}
        </Swiper>

        <Flex vertical gap={8} className={'p-x'}>
          <WidgetSkeleton />
          <WidgetSkeleton />
          <WidgetSkeleton />
        </Flex>
      </Flex>
    </Layout.Content>
  );
};
