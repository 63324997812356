import { Button, Card, Flex, Tag, Typography } from 'antd';

import { BookFilled, IdcardFilled } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { TProfileItemDTO } from '@/api/endpoints/auth';

const colorEduForm = {
  Заочная: 'geekblue',
  Очная: 'red',
  'Очно-заочная': 'purple',
};

type TProfileItemProps = {
  profile: TProfileItemDTO;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export const ProfileItem = ({
  profile,
  onClick,
  loading,
  disabled,
}: TProfileItemProps) => {
  const { styles } = useStyles();
  return (
    <Card className='card-shadowed' size='small' key={profile.id}>
      <Flex vertical gap={8}>
        <Flex justify='space-between'>
          <Typography.Text type='secondary'>{profile.eduLevel}</Typography.Text>
          <Tag
            bordered={false}
            className={styles.tag}
            color={colorEduForm[profile.eduForm]}
          >
            {profile.eduForm}
          </Tag>
        </Flex>
        <Typography.Text strong className={styles.textLarge}>
          {profile.group}
        </Typography.Text>
        <Typography.Text type='secondary'>{profile.specName}</Typography.Text>
        <Flex gap={16}>
          <Flex gap={8}>
            <BookFilled className='icon' />
            <Typography.Text type='secondary'>
              {profile.course} курс
            </Typography.Text>
          </Flex>
          <Flex gap={8}>
            <IdcardFilled className='icon' />
            <Typography.Text type='secondary'>
              {profile.semester} семестр
            </Typography.Text>
          </Flex>
        </Flex>
        {onClick && (
          <Button
            onClick={onClick}
            block
            size='large'
            disabled={disabled}
            loading={loading}
          >
            {disabled ? 'Выбрано' : 'Выбрать'}
          </Button>
        )}
      </Flex>
    </Card>
  );
};
