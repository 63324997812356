import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css }) => ({
  pass: css`
    text-align: center;
  `,
  skeleton: css`
    width: 100%;

    & * {
      margin: 0 auto;
    }
  `,
  skeletonBlock: css`
    width: 100%;
  `,
}));
