import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls }) => ({
  skeletonNode: css`
    &.${prefixCls}-skeleton.${prefixCls}-skeleton-element,
      &.${prefixCls}-skeleton.${prefixCls}-skeleton-element
      .${prefixCls}-skeleton-image {
      width: 100%;
    }
  `,
}));
