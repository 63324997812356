import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  card: css`
    min-height: 130px;
    position: relative;
    background-color: ${token.colorBgLayout};

    & .anticon {
      color: ${token.colorIcon};
    }
  `,
  avatar: css`
    position: absolute;
    bottom: 12px;
    right: 12px;
    background-color: ${token.colorBgBase};
  `,
}));
