import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { EAuthStatus, useAuthStore } from '../model/authStore.ts';

import { fetchProfile, fetchProfiles, fetchSSO } from '@/api/endpoints/auth';

import { EStorageKeys } from '@/configs';

import { ssoRedirect } from '@/utils';

export const useAuth = () => {
  const updateToken = useAuthStore.use.updateToken();
  const updateError = useAuthStore.use.updateError();
  const updateCurrentProfile = useAuthStore.use.updateCurrentProfile();
  const setProfilesList = useAuthStore.use.setProfilesList();
  const updateStatus = useAuthStore.use.updateStatus();
  const setUser = useAuthStore.use.setUser();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const code = searchParams.get('code');
    const reauth = searchParams.get('reauth');
    const token = Cookies.get(EStorageKeys.AUTH_TOKEN);

    (async function () {
      try {
        if (reauth) {
          updateToken(null);
          ssoRedirect();
        } else if (code) {
          const data = await fetchSSO(code);
          if ('profiles' in data) {
            setUser(data.user);
            setProfilesList(data.profiles);
            updateStatus(EAuthStatus.SELECT_PROFILE);
          } else {
            updateCurrentProfile(data.profile);
            updateToken(data.token);
            setUser(data.user);
            updateStatus(EAuthStatus.AUTH);

            fetchProfiles().then((data) => {
              setProfilesList(data.profiles);
            });
          }
        } else if (token) {
          const profileData = await fetchProfile();
          updateCurrentProfile(profileData.profile);
          setUser(profileData.user);
          updateStatus(EAuthStatus.AUTH);

          fetchProfiles().then((data) => {
            setProfilesList(data.profiles);
          });
        } else {
          ssoRedirect();
        }
      } catch (error: unknown) {
        updateToken(null);
        updateStatus(EAuthStatus.UNAUTH);

        if (error instanceof AxiosError) {
          if (error.status === 500 || error.status === 400) {
            updateError(
              'Произошла ошибка сервера. Попробуйте авторизоваться повторно',
            );
          }
        }
      }
    })();
  }, []);
};
