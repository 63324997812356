import { createFileRoute, notFound } from '@tanstack/react-router';
import { AxiosError } from 'axios';

import { announceQueries } from '@/api/endpoints/announce';

import { NotFound } from '@/components/ui/not-found';

import { AnnouncePage, AnnouncePending } from '@/pages/announces/detail';

export const Route = createFileRoute('/announces/$announceId')({
  component: AnnouncePage,
  loader: async ({ params: { announceId }, context: { queryClient } }) => {
    return await queryClient
      .ensureQueryData(announceQueries.detail(announceId))
      .catch((error: AxiosError) => {
        if (error.status === 404) {
          throw notFound();
        }
      });
  },
  pendingComponent: AnnouncePending,
  notFoundComponent: () => (
    <NotFound
      subTitle='Запрашиваемое вами объявление не найдено'
      backLink='/announces'
    />
  ),
});
