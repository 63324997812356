import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  textLarge: css`
    font-size: ${token.fontSizeLG}px;
  `,
  tag: css`
    border-radius: 100px;
  `,
}));
