import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  divider: css`
    height: 2px;
    margin: 0;
    flex: 1 1 auto;
    min-width: auto;
    width: auto;
  `,
  drawer: css`
    & .${prefixCls}-drawer-content {
      border-top-left-radius: ${token.borderRadius}px;
      border-top-right-radius: ${token.borderRadius}px;
    }
    & .${prefixCls}-drawer-body {
      padding: 24px 16px;
    }
  `,
}));
