import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  cardMeta: css`
    .${prefixCls}-card-meta-title {
      margin-bottom: 0 !important;
    }
    .${prefixCls}-card-meta-avatar {
      display: flex;
      align-items: center;
    }
    .${prefixCls}-avatar {
      background-color: #e7405a1f;
    }
    .anticon {
      color: ${token.colorPrimary};
    }
  `,
  swiper: css`
    max-width: 100%;
    width: 100%;
  `,
  swiperSlide: css`
    height: max-content;
    width: auto !important;
  `,
}));
