import { httpWrapper } from '@/api/client';

export type TModuleDTO = {
  name: string;
  desc: string;
  icon: boolean;
  show: boolean;
};
export type TUserDTO = {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  photoUrl: string | null;
  createdAt: string;
  updatedAt: string;
};
export type TFullProfileDTO = {
  id: number;
  group: string;
  access: string;
  photoUrl: string | null;
  modules: TModuleDTO[];
  widgets: string[];
};
export type TProfileItemDTO = {
  id: number;
  group: string;
  eduLevel: string;
  specName: string;
  eduForm: string;
  semester: number;
  course: number;
  createdAt: string;
  updatedAt: string;
};
type TProfileResponse = {
  profile: TFullProfileDTO;
  user: TUserDTO;
  token: string;
};
type TProfilesResponse = { profiles: TProfileItemDTO[]; user: TUserDTO };
type TSSOResponse = TProfileResponse;

export const fetchProfile = () => {
  return httpWrapper.get<TProfileResponse>('profile');
};
export const fetchProfiles = () => {
  return httpWrapper.get<{ profiles: TProfileItemDTO[] }>('profiles');
};
// Если у пользователя один профиль, то приходит полный профиль, если несколько - то список профилей для авторизации за конкретный
export const fetchSSO = (code: string) => {
  return httpWrapper.get<TSSOResponse | TProfilesResponse>(
    `auth/user?code=${code}`,
  );
};

export const changeProfile = (profileId: number) => {
  return httpWrapper.post<TProfileResponse>(`profiles/${profileId}/switch`);
};
export const authByProfile = (profileId: number, email: string) => {
  return httpWrapper.post<TProfileResponse>(`auth/profiles/${profileId}`, {
    email,
  });
};
export const logout = () => {
  return httpWrapper.delete<void>('auth/logout');
};
