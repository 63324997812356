import { Avatar, Button, Flex, Typography } from 'antd';

import { QuestionOutlined } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { externalLinks } from '@/configs';

import bgImage from '@/assets/images/img_support-bg.png';

export const Support = () => {
  const { styles } = useStyles(bgImage);

  return (
    <Flex vertical gap={24} className={styles.support}>
      <Avatar
        shape='circle'
        icon={<QuestionOutlined />}
        size={46}
        className={styles.icon}
      />

      <Flex vertical gap={8} align='center'>
        <Typography.Title level={3} className={styles.title}>
          Вопросы по системе?
        </Typography.Title>
        <Typography.Text className={styles.description}>
          Напишите в службу поддержки и мы ответим в течение суток
        </Typography.Text>
      </Flex>

      <a href={externalLinks.tgSupport} target='_blank' rel='noreferrer'>
        <Button size='large' block>
          Написать поддержке
        </Button>
      </a>
    </Flex>
  );
};
