import { Dayjs } from 'dayjs';

import { TScheduleDayDTO } from '@/api/endpoints/schedule';

import { dateFormats } from '@/configs';

export const getCellColor = (
  dayjsDate: Dayjs,
  activeDays: TScheduleDayDTO[] | undefined,
) => {
  const formattedDate = dayjsDate.format(dateFormats.default);
  const activeDay = activeDays?.find(
    (day) => day.date === formattedDate && day.exam === 1,
  );

  if (activeDay) {
    if (activeDay.exam === 1) {
      return 'green';
    } else if (activeDay.exam === 0) {
      return 'blue';
    }
  }

  return '';
};
