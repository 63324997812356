import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css }) => ({
  disciplines: css`
    max-height: 500px;
    overflow-y: auto;
  `,
  swiper: css`
    max-width: 100%;
    width: 100%;
  `,
  swiperSlide: css`
    height: max-content;
    width: auto !important;
  `,
}));
