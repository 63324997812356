import { Flex, Layout, Skeleton } from 'antd';

export const LessonPending = () => {
  return (
    <Layout.Content className={'content p-x'}>
      <Flex vertical gap={24}>
        <Flex vertical gap={16}>
          <Skeleton paragraph={{ rows: 1 }} active />
          <Skeleton active />
          <Skeleton paragraph={{ rows: 2 }} active />
        </Flex>
        <Skeleton.Input active />
        <Skeleton paragraph={{ rows: 10 }} active />
      </Flex>
    </Layout.Content>
  );
};
