import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  swiper: css`
    max-width: 100%;
    width: 100%;
  `,
  swiperSlide: css`
    height: max-content;
    width: auto !important;
  `,
  card: css`
    width: 146px;
    height: 212px;

    &.${prefixCls}-card-bordered .${prefixCls}-card-cover {
      height: 148px;
      border-radius: ${token.borderRadius}px;
      margin-top: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    & img {
      height: 100%;
      object-fit: cover;
    }
  `,
  active: css`
    border: 1px solid ${token.colorPrimary};
  `,
  storyName: css`
    font-size: ${token.fontSizeSM}px;
  `,
  drawerSwiper: css`
    height: 100%;
    border-top-left-radius: ${token.borderRadius}px;
    border-top-right-radius: ${token.borderRadius}px;
  `,
  storyImage: css`
    &.${prefixCls}-image-img {
      width: 100%;
      height: 358px;
      object-fit: cover;
      border-radius: ${token.borderRadius}px;
    }
  `,
  storyDescription: css`
    white-space: pre-line;
  `,
}));
