import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  menu: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  moduleIcon: css`
    font-size: ${token.fontSizeLG}px;
  `,
}));
