import { Flex, Layout } from 'antd';

import { WidgetSkeleton } from '@/components/ui/skeleton';

export const MainPending = () => {
  return (
    <Layout.Content className='content p-x'>
      <Flex vertical>
        <Flex gap={48} vertical>
          <WidgetSkeleton />
          <WidgetSkeleton />
          <WidgetSkeleton />
          <WidgetSkeleton />
          <WidgetSkeleton />
          <WidgetSkeleton />
        </Flex>
      </Flex>
    </Layout.Content>
  );
};
