import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  backendError: css`
    max-height: 500px;
    overflow-y: auto;
  `,
  row: css`
    &.${prefixCls}-typography, & .${prefixCls}-typography {
      word-break: break-all;
    }
  `,
  title: css`
    &.${prefixCls}-typography {
      margin-bottom: 12px;
    }
  `,
  errorCard: css`
    max-height: 400px;
    overflow-y: auto;
  `,
  result: css`
    padding: 0;
  `,
  resultContainer: css`
    background: ${token.colorBgLayout};
    border-radius: ${token.borderRadius}px;
    padding: 16px 32px;

    & .${prefixCls}-result-icon {
      //transform: scale(0.5);
      //transform-origin: center center;
    }
    & .${prefixCls}-result-image {
      width: auto;
      height: auto;
    }
  `,
}));
