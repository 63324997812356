import { Flex, Layout, Skeleton } from 'antd';
import { v4 } from 'uuid';

export const ServicePending = () => {
  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={24}>
        <Skeleton.Input active />

        <Flex vertical gap={8}>
          {Array(10)
            .fill(0)
            .map(() => (
              <Skeleton.Input size='large' block active key={v4()} />
            ))}
        </Flex>
      </Flex>
    </Layout.Content>
  );
};
