import { dateFormats } from '@/configs';

import { dayjsDefault } from '@/utils';

export const getTitleBySelectedDate = (selectedDate: string, today: string) => {
  const dayjsDate = dayjsDefault(selectedDate);
  const selectedDateYear = dayjsDate.year();
  const todayYear = dayjsDefault(today).year();

  // Выводить год при условии, что год выбранной даты не совпадает с сегодншним годом
  return dayjsDate.format(
    selectedDateYear === todayYear
      ? dateFormats.dayMonth
      : dateFormats.dayMonthYear,
  );
};
