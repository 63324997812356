import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls, token }) => ({
  itemMeta: css`
    & .${prefixCls}-list .${prefixCls}-list-item .${prefixCls}-list-item-meta {
      align-items: center;
    }
  `,
  fw400: css`
    font-weight: 400;
  `,
  upload: css`
    & .${prefixCls}-upload-list .${prefixCls}-upload-list-item {
      padding: 15px 12px;
      background-color: ${token.colorBgLayout};
      height: auto;
      border: 1px solid ${token.colorBorder};
      border-radius: ${token.borderRadius}px;
      color: ${token.colorText};
    }

    &
      .${prefixCls}-upload-list
      .${prefixCls}-upload-list-item
      .${prefixCls}-upload-icon
      .anticon {
      font-size: 22px;
    }
  `,
  paymentHistoryText: css`
    font-size: ${token.fontSizeLG}px;
  `,
  steps: css`
    max-height: calc(var(--vh, 1vh) * 30);
    overflow-y: auto;
  `,
}));
