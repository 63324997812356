import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Button, Flex, Layout } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { SelectProfile } from '@/components/widgets/select-profile';
import { Support } from '@/components/widgets/support';

import { EAuthStatus, useAuthStore } from '@/stores/auth';

const queryClient = new QueryClient();

export const SelectProfilePage = () => {
  const updateStatus = useAuthStore.use.updateStatus();

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Layout.Content className='content p-x'>
          <Flex vertical gap={48}>
            <Flex vertical gap={24}>
              <Button
                shape='round'
                icon={<ArrowLeftOutlined />}
                onClick={() => updateStatus(EAuthStatus.UNAUTH)}
                style={{ width: 'max-content' }}
              >
                Назад
              </Button>
              <SelectProfile />
            </Flex>

            <Support />
          </Flex>
        </Layout.Content>
      </Layout>
    </QueryClientProvider>
  );
};
