import { Flex, Layout, Skeleton } from 'antd';

import { WidgetSkeleton } from '@/components/ui/skeleton';

export const EducationPending = () => {
  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={48}>
        <Flex vertical gap={24}>
          <Skeleton title />
          <Skeleton.Input size='large' block />
          <Skeleton />
        </Flex>

        <WidgetSkeleton />
        <WidgetSkeleton />
        <WidgetSkeleton />
      </Flex>
    </Layout.Content>
  );
};
