import { Outlet, ScrollRestoration, useLocation } from '@tanstack/react-router';
import { Layout, Menu } from 'antd';

import { Footer } from './components/footer';
import { Header } from './components/header';
import { useStyles } from './styles.ts';
import { getMenuItems } from './utils/getMenuItems.tsx';

import { useAuthStore } from '@/stores/auth';

const getSelectedKeys = (pathname: string, modulesNames: string[]) => {
  const moduleByPathname = modulesNames.find(
    (module) =>
      (pathname.includes(module) && module !== '/') || pathname === module,
  );
  return moduleByPathname ? [moduleByPathname] : [];
};

const FooterMenu = () => {
  const { styles } = useStyles();

  const profile = useAuthStore.use.currentProfile();
  const modules = profile.modules;
  const modulesNames = modules.map((module) => module.name);

  const location = useLocation();

  return (
    <Menu
      items={getMenuItems(modules, styles.moduleIcon)}
      mode='inline'
      multiple={false}
      selectedKeys={getSelectedKeys(location.pathname, [
        ...modulesNames,
        '/',
        'service',
      ])}
      className={styles.menu}
      inlineIndent={0}
    />
  );
};

export const RootPage = () => {
  return (
    <>
      <ScrollRestoration getKey={() => location.pathname} />

      <Layout>
        <Header />
        <Outlet />
        <Footer menu={<FooterMenu />} />
      </Layout>
      {/* {import.meta.env.MODE === "development" && <TanStackRouterDevtools />}*/}
    </>
  );
};
