import { FC, ReactNode } from 'react';

import { Loader } from '@/components/ui/loader';

import { AuthPage } from '@/pages/authorization';
import { SelectProfilePage } from '@/pages/select-profile';

import { EAuthStatus, useAuth, useAuthStore } from '@/stores/auth';

export const withAuthProvider = (Component: () => ReactNode): FC => {
  return () => {
    const status = useAuthStore.use.status();
    const profile = useAuthStore.use.currentProfile();

    useAuth();

    if (status === EAuthStatus.LOADING) {
      return <Loader />;
    }

    if (status === EAuthStatus.SELECT_PROFILE) {
      return <SelectProfilePage />;
    }

    // В типах TProfileDTO, но может быть null. Так сделано чтобы в дальнейшем опустить проверку на наличие profile
    if (profile && status === EAuthStatus.AUTH) {
      return <Component />;
    }

    return <AuthPage />;
  };
};
