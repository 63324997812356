import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  moduleDescription: css`
    color: inherit;
    font-size: ${token.fontSizeSM}px;
    margin-inline-start: 0 !important;
  `,
  label: css`
    text-align: center;
  `,
}));
