import { Link } from '@tanstack/react-router';
import { Card } from 'antd';
import { memo } from 'react';

import { AnnounceContent } from './AnnounceContent.tsx';
import { useStyles } from './styles.ts';

import { TAnnounceItemDTO } from '@/api/endpoints/announce';

type TAnnounceItemProps = {
  announce: TAnnounceItemDTO;
};

export const AnnounceItem = memo(({ announce }: TAnnounceItemProps) => {
  const { styles, cx } = useStyles();

  return (
    <Link
      to={'/announces/$announceId'}
      params={{ announceId: String(announce.id) }}
    >
      <Card className={cx('card-shadowed', styles.card)} size='small'>
        <AnnounceContent announce={announce} />
      </Card>
    </Link>
  );
});
