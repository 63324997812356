import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls }) => ({
  w100: css`
    width: 100%;
  `,
  upload: css`
    & .${prefixCls}-upload-select {
      width: 100%;
    }
  `,
}));
