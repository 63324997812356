import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Calendar as AntCalendar,
  Drawer,
  Spin,
  Typography,
} from 'antd';
import { SelectInfo } from 'antd/es/calendar/generateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { CalendarCell } from './CalendarCell.tsx';
import { CalendarHeader } from './CalendarHeader.tsx';
import { useStyles } from './styles.ts';

import { checkDateDisabled, getDateSplitValue } from '../utils';

import { scheduleQueries } from '@/api/endpoints/schedule';

import { dateFormats } from '@/configs';

type TCalendarProps = {
  selectedDate: string;
  onDateSelect: (date: string) => void;
  isDrawerOpen: boolean;
  onClose: () => void;
};

export const Calendar = ({
  selectedDate,
  onDateSelect,
  isDrawerOpen,
  onClose,
}: TCalendarProps) => {
  const { styles } = useStyles();

  const [calendarState, setCalendarState] = useState(
    getDateSplitValue(selectedDate),
  );
  const { data, isPending } = useQuery(
    scheduleQueries.month(calendarState.month, calendarState.year),
  );

  const onCalendarSelect = (value: Dayjs, selectInfo: SelectInfo) => {
    if (selectInfo.source === 'date') {
      onDateSelect(value.format(dateFormats.default));
      onClose();
    } else if (selectInfo.source === 'customize') {
      const month = Number(value.format('M'));
      const year = Number(value.format('YYYY'));

      setCalendarState({ month, year });
    }
  };

  return (
    <Drawer
      title={
        <Typography.Title level={4}>
          Календарь {isPending && <Spin spinning />}
        </Typography.Title>
      }
      closeIcon={null}
      open={isDrawerOpen}
      placement='bottom'
      height={600}
      extra={
        <Button
          onClick={onClose}
          type='text'
          size='large'
          icon={<CloseOutlined />}
        />
      }
      onClose={onClose}
    >
      <AntCalendar
        fullscreen={false}
        disabledDate={(date) => checkDateDisabled(date, data)}
        fullCellRender={(date, info) => (
          <CalendarCell dayjsDate={date} info={info} activeDays={data} />
        )}
        defaultValue={dayjs(selectedDate, dateFormats.default)}
        rootClassName={styles.calendar}
        onSelect={onCalendarSelect}
        headerRender={CalendarHeader}
      />
    </Drawer>
  );
};
