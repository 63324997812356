import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css }) => ({
  swiper: css`
    max-width: 100%;
    width: 100%;
  `,
  swiperSlide: css`
    height: max-content;
    width: auto !important;
  `,
}));
