import { Col, Flex, Layout, Row, Skeleton } from 'antd';

import { useStyles } from './styles.ts';

export const PortfolioPending = () => {
  const { styles } = useStyles();

  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={32}>
        <Skeleton.Input size='large' block active />

        <Row gutter={[8, 8]}>
          {Array(8)
            .fill(0)
            .map((_, i) => (
              <Col span={12} key={i}>
                <Skeleton.Node active rootClassName={styles.skeletonNode} />
              </Col>
            ))}
        </Row>
      </Flex>
    </Layout.Content>
  );
};
