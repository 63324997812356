import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext } from '@tanstack/react-router';

import { RootPage } from '@/pages/root';

import { TAuthStore } from '@/stores/auth';

export interface MyRouterContext {
  queryClient: QueryClient;
  profileStore: TAuthStore;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootPage,
  validateSearch: (
    search: Record<string, unknown>,
  ): Record<string, unknown> => {
    Object.keys(search).forEach((key) => {
      if (['code', 'reauth'].includes(key)) {
        delete search[key];
      }
    });

    return search;
  },
});
