import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import {
  educationQueries,
  TEducationDisciplineSearch,
} from '@/api/endpoints/education';

import { EducationPage, EducationPending } from '@/pages/education/index';

const educationSearchSchema = z.object({
  query: z.string().optional(),
  semester: z.union([z.number(), z.literal('retake')]).optional(),
});

export const Route = createFileRoute('/education/')({
  component: EducationPage,
  pendingComponent: EducationPending,
  loader: async ({
    context,
  }: {
    context: { queryClient: QueryClient };
    deps: TEducationDisciplineSearch;
  }) => {
    return context.queryClient.ensureQueryData(educationQueries.semesters());
  },
  validateSearch: zodValidator({
    schema: educationSearchSchema,
    input: 'output',
    output: 'input',
  }),
});
