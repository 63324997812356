import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import dayjs from 'dayjs';

import { scheduleQueries } from '@/api/endpoints/schedule';

import { dateFormats } from '@/configs';

import { SchedulePage, SchedulePending } from '@/pages/schedule/index';

type TScheduleSearch = {
  selectedDate: string;
};

export const Route = createFileRoute('/schedule/')({
  component: SchedulePage,
  pendingComponent: SchedulePending,
  loader: async ({
    context,
    deps,
  }: {
    context: { queryClient: QueryClient };
    deps: TScheduleSearch;
  }) => {
    return await context.queryClient.ensureQueryData(
      scheduleQueries.byDate(deps.selectedDate),
    );
  },
  validateSearch: (search: Record<string, unknown>): TScheduleSearch => {
    return {
      selectedDate:
        typeof search.selectedDate === 'string' &&
        dayjs(search.selectedDate, dateFormats.default, true).isValid()
          ? search.selectedDate
          : dayjs().format(dateFormats.default),
    };
  },
  loaderDeps: ({ search: { selectedDate } }) => ({
    selectedDate,
  }),
});
