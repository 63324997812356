import { Col, Flex, Layout, Row, Skeleton } from 'antd';
import { v4 } from 'uuid';

import { useStyles } from './styles.ts';

export const FinancesPending = () => {
  const { styles } = useStyles();

  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={48}>
        <Skeleton.Input active />

        <Row gutter={[8, 8]}>
          {Array(4)
            .fill(0)
            .map(() => (
              <Col span={12} key={v4()}>
                <Skeleton.Node active className={styles.skeletonNode} />
              </Col>
            ))}
        </Row>

        <Skeleton.Button block size='large' />

        <Skeleton paragraph={{ rows: 6 }} active />
      </Flex>
    </Layout.Content>
  );
};
