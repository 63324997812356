import { Flex, Layout, Skeleton } from 'antd';

export const AnnouncePending = () => {
  return (
    <Layout.Content className={'content p-x'}>
      <Flex gap={24} vertical>
        <Skeleton active title />
        <Skeleton paragraph={{ rows: 20 }} active />
      </Flex>
    </Layout.Content>
  );
};
