import { Flex, Layout, List, Skeleton } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 } from 'uuid';

export const AnnouncesPending = () => {
  const list = Array(4).fill(0);

  return (
    <Layout.Content className='content'>
      <Flex vertical gap={24}>
        <Skeleton.Input active className={'p-x'} />

        <Swiper
          grabCursor
          spaceBetween={8}
          slidesPerView='auto'
          initialSlide={0}
          className={'p-x'}
        >
          {Array(5)
            .fill(0)
            .map(() => (
              <SwiperSlide key={v4()}>
                <Skeleton.Button active />
              </SwiperSlide>
            ))}
        </Swiper>

        <List
          className={'p-x'}
          itemLayout='vertical'
          size='large'
          dataSource={list}
          renderItem={(item) => (
            <List.Item key={item.title}>
              <Skeleton active>
                <List.Item.Meta title description />
              </Skeleton>
            </List.Item>
          )}
        />
      </Flex>
    </Layout.Content>
  );
};
