import { Flex, Layout, Skeleton } from 'antd';

import { useStyles } from './styles.ts';

export const PassPending = () => {
  const { styles } = useStyles();

  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={48} className={styles.skeleton}>
        <Flex vertical gap={12} align='center' className={styles.skeletonBlock}>
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton.Avatar size={128} active />
          <Flex
            vertical
            gap={4}
            align='center'
            className={styles.skeletonBlock}
          >
            <Skeleton.Input block active />
            <Skeleton.Input active />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          </Flex>
        </Flex>

        <Flex vertical gap={12} className={styles.skeletonBlock}>
          <Skeleton active title={false} paragraph={{ rows: 1, width: 100 }} />
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 1, width: '100%' }}
          />
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 1, width: '100%' }}
          />
        </Flex>

        <Skeleton active title={false} paragraph={{ rows: 1, width: 300 }} />
      </Flex>
    </Layout.Content>
  );
};
