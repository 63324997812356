import { Flex, Layout, Skeleton } from 'antd';

export const DisciplinePending = () => {
  return (
    <Layout.Content className='content p-x'>
      <Flex vertical gap={48}>
        <Flex vertical gap={24}>
          <Flex vertical gap={8}>
            <Skeleton.Input block size='large' active />
            <Skeleton.Input size='large' active />
          </Flex>

          <Skeleton.Button size='large' active block />
        </Flex>

        <Flex vertical gap={24}>
          <Skeleton paragraph={{ rows: 5 }} active />
          <Skeleton paragraph={{ rows: 5 }} active />
        </Flex>
      </Flex>
    </Layout.Content>
  );
};
