import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import {
  ExternalResourcesPage,
  ExternalResourcesPending,
} from '@/pages/library/external-resources';

const searchSchema = z.object({
  query: z.string().optional(),
});

export const Route = createFileRoute('/library/external-resources')({
  component: ExternalResourcesPage,
  pendingComponent: ExternalResourcesPending,
  validateSearch: searchSchema,
});
