import { createStyles } from 'antd-style';

import { geekblue, green, red } from '@ant-design/colors';

export const useStyles = createStyles(({ css, token }) => ({
  discipline: css`
    font-size: ${token.fontSizeLG}px;
  `,
  icon: css`
    margin-right: 8px;
  `,
  card: css`
    position: relative;
  `,
  link: css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  `,
  externalLink: css`
    position: relative;
    z-index: 1;
  `,
  tag: css`
    margin-inline-end: 0;
    border-radius: 100px;
  `,
  greenTag: css`
    background-color: ${green[0]};
    color: ${green[5]};
  `,
  blueTag: css`
    background-color: ${geekblue[0]};
    color: ${geekblue[5]};
  `,
  redTag: css`
    background-color: ${red[0]};
    color: ${red[5]};
  `,
  canceled: css`
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.7;
  `,
}));
