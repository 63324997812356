import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactNode } from 'react';

import { useThemeConfig } from '@/stores/theme';

import 'dayjs/locale/ru.js';

dayjs.locale('ru');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');
dayjs.extend(customParseFormat);

export const withAntConfigProvider = (component: () => ReactNode) => () => {
  const getAntdThemeConfig = useThemeConfig();

  return (
    <ConfigProvider
      form={{
        validateMessages: {
          required: 'Поле "${label}" обязательно для заполнения!',
        },
      }}
      locale={ru_RU}
      theme={getAntdThemeConfig}
      prefixCls='ins'
    >
      {component()}
    </ConfigProvider>
  );
};
