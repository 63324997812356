// import Cookies from 'js-cookie';

import { EThemes } from '../types.ts';

// import { EStorageKeys } from '@/shared/configs';

export const getInitialTheme = (): EThemes => {
  return EThemes.LIGHT;
  // const savedTheme = Cookies.get(EStorageKeys.THEME);
  //
  // if (savedTheme) {
  //   return savedTheme as EThemes;
  // } else {
  //   return matchMedia('(prefers-color-scheme: dark)').matches
  //     ? EThemes.DARK
  //     : EThemes.LIGHT;
  // }
};
