import { EThemes } from '../types.ts';

export const updateStylesFile = (theme: EThemes) => {
  const existingLink = document.getElementById('theme-style');

  if (existingLink) {
    existingLink.remove();
  }

  const link = document.createElement('link');

  link.id = 'theme-style';
  link.rel = 'stylesheet';
  link.href = `/styles/${theme}-theme.css`;

  document.head.appendChild(link);
};
