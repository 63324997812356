import { Link } from '@tanstack/react-router';
import { Card } from 'antd';

import { LessonContent } from './LessonContent.tsx';
import { useStyles } from './styles.ts';

import { TScheduleLessonDTO } from '@/api/endpoints/schedule';

type TScheduleLessonProps = {
  lesson: TScheduleLessonDTO;
  isLink?: boolean;
};

export const ScheduleLesson = ({ lesson }: TScheduleLessonProps) => {
  const { styles, cx } = useStyles();

  return (
    <Card className={cx('card-shadowed', styles.card)} size='small'>
      <Link
        to={'/schedule/$lessonId'}
        params={{ lessonId: String(lesson.id) }}
        className={styles.link}
      />

      <LessonContent lesson={lesson} />
    </Card>
  );
};
