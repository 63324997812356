import { ErrorComponentProps } from '@tanstack/react-router';
import { Button, Card, Flex, Result, Typography } from 'antd';
import axios from 'axios';

import { AxiosErrorBoundary } from './AxiosErrorBoundary.tsx';
import { useStyles } from './styles.ts';

interface IErrorBoundaryProps {
  error: ErrorComponentProps;
  on401: () => void;
}

export const ErrorBoundary = ({ error, on401 }: IErrorBoundaryProps) => {
  const { styles } = useStyles();

  if (axios.isAxiosError(error.error)) {
    if (error.error.status === 401) {
      on401();
    } else {
      return <AxiosErrorBoundary error={error.error} />;
    }
  }

  return (
    <Result
      status='error'
      title='Непредвиденная клиентская ошибка'
      subTitle='Пожалуйста, свяжитесь с разработчиками и предоставьте им скриншот кода данной ошибки'
      className={styles.result}
      extra={
        <Flex vertical gap='large' align='center' justify='center'>
          <Card className={styles.errorCard}>
            <Flex vertical>
              <Typography.Title level={4}>
                {error.error.name} - {error.error.message}
              </Typography.Title>
              <Typography.Text>{error.error.stack}</Typography.Text>
              <Typography.Text>{error.info?.componentStack}</Typography.Text>
            </Flex>
          </Card>

          <Button block onClick={error.reset} size='large'>
            Попробовать снова
          </Button>
        </Flex>
      }
    />
  );
};
