// import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { Layout, Spin } from 'antd';
import { YMInitializer } from 'react-yandex-metrika';

import { routeTree } from './routeTree.gen.ts';

import { ErrorBoundary } from '@/components/ui/error';
import { NotFound } from '@/components/ui/not-found';

import { withProviders } from '@/providers';

import { EAuthStatus, useAuthStore } from '@/stores/auth';

import { MyRouterContext } from '@/routes/__root.tsx';

import '@/assets/scripts';
import '@/assets/styles/index.scss';

const router = createRouter({
  routeTree,
  context: {
    queryClient: undefined!,
    profileStore: undefined!,
  },
  defaultPreload: 'intent',
  defaultNotFoundComponent: () => <NotFound />,
  defaultPendingComponent: () => <Spin />,
  defaultErrorComponent: (error) => (
    <Layout.Content className='content p-x'>
      <ErrorBoundary
        on401={() => {
          const updateStatus = useAuthStore.use.updateStatus();
          const updateToken = useAuthStore.use.updateToken();
          updateStatus(EAuthStatus.INVALID_TOKEN);
          updateToken(null);
        }}
        error={error}
      />
    </Layout.Content>
  ),
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//     Sentry.tanstackRouterBrowserTracingIntegration(router),
//     Sentry.globalHandlersIntegration({
//       onerror: false,
//       onunhandledrejection: false,
//     }),
//   ],
//   // Tracing
//   tracesSampleRate: 0.2, //  Capture 20% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/v2\.instudy\.online/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const MyApp = () => {
  const queryClient = useQueryClient();

  const context: MyRouterContext = {
    queryClient,
    profileStore: useAuthStore(),
  };

  return (
    <>
      <RouterProvider router={router} context={context} />

      {process.env.NODE_ENV === 'production' && (
        <YMInitializer
          accounts={[99617386]}
          attrs={{}}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version='2'
        />
      )}
    </>
  );
};
const AppWithProviders = withProviders(MyApp);

export default AppWithProviders;
