import { Dayjs } from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/lib/interface';

import { useStyles } from './styles.ts';

import { getCellColor } from '../utils';

import { TScheduleDayDTO } from '@/api/endpoints/schedule';

type TCalendarCellProps = {
  dayjsDate: Dayjs;
  info: CellRenderInfo<Dayjs>;
  activeDays: TScheduleDayDTO[] | undefined;
};

export const CalendarCell = ({
  dayjsDate,
  info,
  activeDays,
}: TCalendarCellProps) => {
  const { styles, cx } = useStyles();

  return (
    <div
      className={cx(
        info.originNode.props.className,
        styles[getCellColor(dayjsDate, activeDays)],
      )}
    >
      {dayjsDate.format('DD')}
    </div>
  );
};
