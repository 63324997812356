import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  content: css`
    * {
      font-family: ${token.fontFamily};
      font-size: ${token.fontSize};
      line-height: ${token.lineHeight};
      white-space: pre-line;
    }
    ul {
      list-style: disc;
      padding-left: 16px;
    }
    ol {
      list-style: decimal;
      padding-left: 16px;
    }
  `,
}));
