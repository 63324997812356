import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { financeQueries } from '@/api/endpoints/finance';

import { FinancesPage, FinancesPending } from '@/pages/finances/index';

export const Route = createFileRoute('/finances/')({
  component: FinancesPage,
  pendingComponent: FinancesPending,
  loader: async ({ context }: { context: { queryClient: QueryClient } }) => {
    return await context.queryClient.ensureQueryData(financeQueries.all());
  },
});
