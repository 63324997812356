import { useQuery } from '@tanstack/react-query';
import { Dayjs, MonthNames } from 'dayjs';

import { getMonthsPagination } from '../utils';

import { scheduleQueries } from '@/api/endpoints/schedule';

import { capitalizeFirstLetter, dayjsDefault } from '@/utils';

type TSelectOption = { label: string | number; value: number };

export const useSemesterBoundaries = (value: Dayjs) => {
  const { data: semesterBoundaries } = useQuery(scheduleQueries.semester());

  const semesterStart = dayjsDefault(semesterBoundaries?.start);
  const semesterEnd = dayjsDefault(semesterBoundaries?.finish);

  const selectedDateYear = value.year();
  const selectedDateMonth = value.month();
  let selectedDateClone = value.clone();
  const localeData = value.localeData();

  const firstMonthIndex = 0;
  const lastMonthIndex = 11;

  let monthsOptions: TSelectOption[] = [];
  let yearOptions: TSelectOption[] = [];
  const monthsNames: MonthNames[] = [];

  for (let i = 0; i < 12; i++) {
    selectedDateClone = selectedDateClone.month(i);
    monthsNames.push(localeData.months(selectedDateClone));
  }

  for (let i = firstMonthIndex; i <= lastMonthIndex; i++) {
    monthsOptions.push({
      label: capitalizeFirstLetter(monthsNames[i]),
      value: i,
    });
  }

  for (let i = selectedDateYear - 10; i <= selectedDateYear + 10; i++) {
    yearOptions.push({
      label: i,
      value: i,
    });
  }

  if (semesterBoundaries) {
    monthsOptions = monthsOptions.filter((_, i) => {
      return (
        (selectedDateYear === semesterStart.year() &&
          i >= semesterStart.month()) ||
        (selectedDateYear === semesterEnd.year() && i <= semesterEnd.month())
      );
    });

    yearOptions = yearOptions.filter((year) => {
      return (
        year.value >= semesterStart.year() && year.value <= semesterEnd.year()
      );
    });
  }

  const { previousMonthName, nextMonthName } = getMonthsPagination(
    selectedDateMonth,
    monthsNames,
  );

  return {
    semesterStart,
    semesterEnd,
    yearOptions,
    monthsOptions,
    selectedDateYear,
    selectedDateMonth,
    previousMonthName,
    nextMonthName,
  };
};
