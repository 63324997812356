import { theme as antdTheme, ThemeConfig } from 'antd';

import { EThemes } from './types.ts';

const colors = {
  primary: {
    [EThemes.LIGHT]: '#e40b28',
    [EThemes.DARK]: '#DE2846',
  },
  colorText: {
    [EThemes.LIGHT]: '#121212',
    [EThemes.DARK]: '#121212',
  },
  colorIcon: {
    [EThemes.LIGHT]: '#00000073',
    [EThemes.DARK]: '#00000073',
  },
  colorInfo: {
    [EThemes.LIGHT]: '#e40b28',
    [EThemes.DARK]: '#e40b28',
  },
  colorSuccess: {
    [EThemes.LIGHT]: '#4ac10f',
    [EThemes.DARK]: '#4ac10f',
  },
  mainBg: {
    [EThemes.LIGHT]: '#FFFFFF',
    [EThemes.DARK]: '#141414',
  },
  secondaryBg: {
    [EThemes.LIGHT]: '#F5F5F5',
    [EThemes.DARK]: '#000000',
  },
  border: {
    [EThemes.LIGHT]: '#D9D9D9',
    [EThemes.DARK]: '#424242',
  },
  borderSecondary: {
    [EThemes.LIGHT]: '#F0F0F0',
    [EThemes.DARK]: '#303030',
  },
  // modal
  contentBg: {
    [EThemes.LIGHT]: '#FFFFFF',
    [EThemes.DARK]: '#171717',
  },
  //
  popoversBg: {
    [EThemes.LIGHT]: '#FFFFFF',
    [EThemes.DARK]: '#222222',
  },
};

export const getThemeConfig = (theme: EThemes): ThemeConfig => {
  return {
    cssVar: true,
    hashed: false,
    algorithm:
      theme === EThemes.LIGHT
        ? antdTheme.defaultAlgorithm
        : antdTheme.darkAlgorithm,
    token: {
      zIndexPopupBase: 100,
      colorLink: colors.primary[theme],
      colorPrimary: colors.primary[theme],
      colorTextBase: colors.colorText[theme],
      colorSuccess: colors.colorSuccess[theme],
      colorInfo: colors.colorInfo[theme],
      colorBgBase: colors.mainBg[theme],
      colorBgContainer: colors.mainBg[theme],
      colorBgLayout: colors.secondaryBg[theme],
      colorBorder: colors.border[theme],
      colorBorderSecondary: colors.borderSecondary[theme],
      colorBgElevated: colors.popoversBg[theme],
      colorIcon: colors.colorIcon[theme],
      borderRadius: 8,
      fontSize: 16,
      borderRadiusSM: 4,
    },
    components: {
      Button: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
        paddingInlineSM: 12,
        paddingInline: 24,
        paddingInlineLG: 24,
      },
      Input: {
        inputFontSize: 16,
        inputFontSizeSM: 16,
        controlHeightLG: 48,
        controlHeight: 42,
        controlHeightSM: 32,
      },
      Menu: {
        controlHeightLG: 48,
        activeBarBorderWidth: 0,
        activeBarHeight: 0,
        activeBarWidth: 0,
        iconSize: 16,
        itemColor: colors.colorIcon[theme],
        itemHoverColor: colors.colorIcon[theme],
        itemSelectedBg: 'transparent',
        itemSelectedColor: '#000000E0',
        itemHoverBg: 'transparent',
        itemMarginInline: 0,
        itemPaddingInline: 0,
        horizontalItemSelectedColor: '#000000E0',
      },
      Segmented: {
        controlHeightLG: 52,
        controlHeight: 46,
        controlHeightSM: 38,
      },
      Skeleton: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
      },
      Pagination: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
      },
      Form: {
        controlHeightSM: 32,
        controlHeightLG: 48,
        controlHeight: 42,
      },
      InputNumber: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
      },
      DatePicker: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
      },
      Mentions: {
        controlHeight: 42,
      },
      Radio: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
      },
      Select: {
        controlHeight: 42,
        controlHeightLG: 48,
        controlHeightSM: 32,
      },
      Switch: {
        algorithm: true,
      },
      TreeSelect: {
        algorithm: true,
      },
      Layout: {
        headerHeight: 48,
        headerPadding: '8px 16px',
        footerPadding: '12px 8px 20px 8px',
        headerBg: colors.contentBg[theme],
        bodyBg: colors.contentBg[theme],
        footerBg: colors.contentBg[theme],
      },
      Table: {
        headerBg: 'rgb(255,255,255)',
        headerFilterHoverBg: 'rgba(0,0,0,0.04)',
        headerSortActiveBg: 'rgba(240,240,240,0)',
      },
      Typography: {
        titleMarginBottom: 0,
        titleMarginTop: 0,
      },
      Divider: {
        margin: 0,
        marginLG: 0,
      },
      Card: {
        padding: 12,
      },
    },
  };
};
