/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as ServiceImport } from './routes/service';
import { Route as PassImport } from './routes/pass';
import { Route as IndexImport } from './routes/index';
import { Route as ScheduleIndexImport } from './routes/schedule/index';
import { Route as PortfolioIndexImport } from './routes/portfolio/index';
import { Route as LibraryIndexImport } from './routes/library/index';
import { Route as FinancesIndexImport } from './routes/finances/index';
import { Route as EducationIndexImport } from './routes/education/index';
import { Route as AnnouncesIndexImport } from './routes/announces/index';
import { Route as ScheduleLessonIdImport } from './routes/schedule/$lessonId';
import { Route as LibraryExternalResourcesImport } from './routes/library/external-resources';
import { Route as LibraryExternalArticlesImport } from './routes/library/external-articles';
import { Route as FinancesInvoiceFormImport } from './routes/finances/invoice-form';
import { Route as EducationDisciplineIdImport } from './routes/education/$disciplineId';
import { Route as AnnouncesAnnounceIdImport } from './routes/announces/$announceId';
import { Route as EducationDisciplineIdMaterialsImport } from './routes/education_/$disciplineId/materials';

// Create/Update Routes

const ServiceRoute = ServiceImport.update({
  id: '/service',
  path: '/service',
  getParentRoute: () => rootRoute,
} as any);

const PassRoute = PassImport.update({
  id: '/pass',
  path: '/pass',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const ScheduleIndexRoute = ScheduleIndexImport.update({
  id: '/schedule/',
  path: '/schedule/',
  getParentRoute: () => rootRoute,
} as any);

const PortfolioIndexRoute = PortfolioIndexImport.update({
  id: '/portfolio/',
  path: '/portfolio/',
  getParentRoute: () => rootRoute,
} as any);

const LibraryIndexRoute = LibraryIndexImport.update({
  id: '/library/',
  path: '/library/',
  getParentRoute: () => rootRoute,
} as any);

const FinancesIndexRoute = FinancesIndexImport.update({
  id: '/finances/',
  path: '/finances/',
  getParentRoute: () => rootRoute,
} as any);

const EducationIndexRoute = EducationIndexImport.update({
  id: '/education/',
  path: '/education/',
  getParentRoute: () => rootRoute,
} as any);

const AnnouncesIndexRoute = AnnouncesIndexImport.update({
  id: '/announces/',
  path: '/announces/',
  getParentRoute: () => rootRoute,
} as any);

const ScheduleLessonIdRoute = ScheduleLessonIdImport.update({
  id: '/schedule/$lessonId',
  path: '/schedule/$lessonId',
  getParentRoute: () => rootRoute,
} as any);

const LibraryExternalResourcesRoute = LibraryExternalResourcesImport.update({
  id: '/library/external-resources',
  path: '/library/external-resources',
  getParentRoute: () => rootRoute,
} as any);

const LibraryExternalArticlesRoute = LibraryExternalArticlesImport.update({
  id: '/library/external-articles',
  path: '/library/external-articles',
  getParentRoute: () => rootRoute,
} as any);

const FinancesInvoiceFormRoute = FinancesInvoiceFormImport.update({
  id: '/finances/invoice-form',
  path: '/finances/invoice-form',
  getParentRoute: () => rootRoute,
} as any);

const EducationDisciplineIdRoute = EducationDisciplineIdImport.update({
  id: '/education/$disciplineId',
  path: '/education/$disciplineId',
  getParentRoute: () => rootRoute,
} as any);

const AnnouncesAnnounceIdRoute = AnnouncesAnnounceIdImport.update({
  id: '/announces/$announceId',
  path: '/announces/$announceId',
  getParentRoute: () => rootRoute,
} as any);

const EducationDisciplineIdMaterialsRoute =
  EducationDisciplineIdMaterialsImport.update({
    id: '/education_/$disciplineId/materials',
    path: '/education/$disciplineId/materials',
    getParentRoute: () => rootRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/pass': {
      id: '/pass';
      path: '/pass';
      fullPath: '/pass';
      preLoaderRoute: typeof PassImport;
      parentRoute: typeof rootRoute;
    };
    '/service': {
      id: '/service';
      path: '/service';
      fullPath: '/service';
      preLoaderRoute: typeof ServiceImport;
      parentRoute: typeof rootRoute;
    };
    '/announces/$announceId': {
      id: '/announces/$announceId';
      path: '/announces/$announceId';
      fullPath: '/announces/$announceId';
      preLoaderRoute: typeof AnnouncesAnnounceIdImport;
      parentRoute: typeof rootRoute;
    };
    '/education/$disciplineId': {
      id: '/education/$disciplineId';
      path: '/education/$disciplineId';
      fullPath: '/education/$disciplineId';
      preLoaderRoute: typeof EducationDisciplineIdImport;
      parentRoute: typeof rootRoute;
    };
    '/finances/invoice-form': {
      id: '/finances/invoice-form';
      path: '/finances/invoice-form';
      fullPath: '/finances/invoice-form';
      preLoaderRoute: typeof FinancesInvoiceFormImport;
      parentRoute: typeof rootRoute;
    };
    '/library/external-articles': {
      id: '/library/external-articles';
      path: '/library/external-articles';
      fullPath: '/library/external-articles';
      preLoaderRoute: typeof LibraryExternalArticlesImport;
      parentRoute: typeof rootRoute;
    };
    '/library/external-resources': {
      id: '/library/external-resources';
      path: '/library/external-resources';
      fullPath: '/library/external-resources';
      preLoaderRoute: typeof LibraryExternalResourcesImport;
      parentRoute: typeof rootRoute;
    };
    '/schedule/$lessonId': {
      id: '/schedule/$lessonId';
      path: '/schedule/$lessonId';
      fullPath: '/schedule/$lessonId';
      preLoaderRoute: typeof ScheduleLessonIdImport;
      parentRoute: typeof rootRoute;
    };
    '/announces/': {
      id: '/announces/';
      path: '/announces';
      fullPath: '/announces';
      preLoaderRoute: typeof AnnouncesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/education/': {
      id: '/education/';
      path: '/education';
      fullPath: '/education';
      preLoaderRoute: typeof EducationIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/finances/': {
      id: '/finances/';
      path: '/finances';
      fullPath: '/finances';
      preLoaderRoute: typeof FinancesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/library/': {
      id: '/library/';
      path: '/library';
      fullPath: '/library';
      preLoaderRoute: typeof LibraryIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/portfolio/': {
      id: '/portfolio/';
      path: '/portfolio';
      fullPath: '/portfolio';
      preLoaderRoute: typeof PortfolioIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/schedule/': {
      id: '/schedule/';
      path: '/schedule';
      fullPath: '/schedule';
      preLoaderRoute: typeof ScheduleIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/education_/$disciplineId/materials': {
      id: '/education_/$disciplineId/materials';
      path: '/education/$disciplineId/materials';
      fullPath: '/education/$disciplineId/materials';
      preLoaderRoute: typeof EducationDisciplineIdMaterialsImport;
      parentRoute: typeof rootRoute;
    };
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/pass': typeof PassRoute;
  '/service': typeof ServiceRoute;
  '/announces/$announceId': typeof AnnouncesAnnounceIdRoute;
  '/education/$disciplineId': typeof EducationDisciplineIdRoute;
  '/finances/invoice-form': typeof FinancesInvoiceFormRoute;
  '/library/external-articles': typeof LibraryExternalArticlesRoute;
  '/library/external-resources': typeof LibraryExternalResourcesRoute;
  '/schedule/$lessonId': typeof ScheduleLessonIdRoute;
  '/announces': typeof AnnouncesIndexRoute;
  '/education': typeof EducationIndexRoute;
  '/finances': typeof FinancesIndexRoute;
  '/library': typeof LibraryIndexRoute;
  '/portfolio': typeof PortfolioIndexRoute;
  '/schedule': typeof ScheduleIndexRoute;
  '/education/$disciplineId/materials': typeof EducationDisciplineIdMaterialsRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/pass': typeof PassRoute;
  '/service': typeof ServiceRoute;
  '/announces/$announceId': typeof AnnouncesAnnounceIdRoute;
  '/education/$disciplineId': typeof EducationDisciplineIdRoute;
  '/finances/invoice-form': typeof FinancesInvoiceFormRoute;
  '/library/external-articles': typeof LibraryExternalArticlesRoute;
  '/library/external-resources': typeof LibraryExternalResourcesRoute;
  '/schedule/$lessonId': typeof ScheduleLessonIdRoute;
  '/announces': typeof AnnouncesIndexRoute;
  '/education': typeof EducationIndexRoute;
  '/finances': typeof FinancesIndexRoute;
  '/library': typeof LibraryIndexRoute;
  '/portfolio': typeof PortfolioIndexRoute;
  '/schedule': typeof ScheduleIndexRoute;
  '/education/$disciplineId/materials': typeof EducationDisciplineIdMaterialsRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/pass': typeof PassRoute;
  '/service': typeof ServiceRoute;
  '/announces/$announceId': typeof AnnouncesAnnounceIdRoute;
  '/education/$disciplineId': typeof EducationDisciplineIdRoute;
  '/finances/invoice-form': typeof FinancesInvoiceFormRoute;
  '/library/external-articles': typeof LibraryExternalArticlesRoute;
  '/library/external-resources': typeof LibraryExternalResourcesRoute;
  '/schedule/$lessonId': typeof ScheduleLessonIdRoute;
  '/announces/': typeof AnnouncesIndexRoute;
  '/education/': typeof EducationIndexRoute;
  '/finances/': typeof FinancesIndexRoute;
  '/library/': typeof LibraryIndexRoute;
  '/portfolio/': typeof PortfolioIndexRoute;
  '/schedule/': typeof ScheduleIndexRoute;
  '/education_/$disciplineId/materials': typeof EducationDisciplineIdMaterialsRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/pass'
    | '/service'
    | '/announces/$announceId'
    | '/education/$disciplineId'
    | '/finances/invoice-form'
    | '/library/external-articles'
    | '/library/external-resources'
    | '/schedule/$lessonId'
    | '/announces'
    | '/education'
    | '/finances'
    | '/library'
    | '/portfolio'
    | '/schedule'
    | '/education/$disciplineId/materials';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/pass'
    | '/service'
    | '/announces/$announceId'
    | '/education/$disciplineId'
    | '/finances/invoice-form'
    | '/library/external-articles'
    | '/library/external-resources'
    | '/schedule/$lessonId'
    | '/announces'
    | '/education'
    | '/finances'
    | '/library'
    | '/portfolio'
    | '/schedule'
    | '/education/$disciplineId/materials';
  id:
    | '__root__'
    | '/'
    | '/pass'
    | '/service'
    | '/announces/$announceId'
    | '/education/$disciplineId'
    | '/finances/invoice-form'
    | '/library/external-articles'
    | '/library/external-resources'
    | '/schedule/$lessonId'
    | '/announces/'
    | '/education/'
    | '/finances/'
    | '/library/'
    | '/portfolio/'
    | '/schedule/'
    | '/education_/$disciplineId/materials';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  PassRoute: typeof PassRoute;
  ServiceRoute: typeof ServiceRoute;
  AnnouncesAnnounceIdRoute: typeof AnnouncesAnnounceIdRoute;
  EducationDisciplineIdRoute: typeof EducationDisciplineIdRoute;
  FinancesInvoiceFormRoute: typeof FinancesInvoiceFormRoute;
  LibraryExternalArticlesRoute: typeof LibraryExternalArticlesRoute;
  LibraryExternalResourcesRoute: typeof LibraryExternalResourcesRoute;
  ScheduleLessonIdRoute: typeof ScheduleLessonIdRoute;
  AnnouncesIndexRoute: typeof AnnouncesIndexRoute;
  EducationIndexRoute: typeof EducationIndexRoute;
  FinancesIndexRoute: typeof FinancesIndexRoute;
  LibraryIndexRoute: typeof LibraryIndexRoute;
  PortfolioIndexRoute: typeof PortfolioIndexRoute;
  ScheduleIndexRoute: typeof ScheduleIndexRoute;
  EducationDisciplineIdMaterialsRoute: typeof EducationDisciplineIdMaterialsRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  PassRoute: PassRoute,
  ServiceRoute: ServiceRoute,
  AnnouncesAnnounceIdRoute: AnnouncesAnnounceIdRoute,
  EducationDisciplineIdRoute: EducationDisciplineIdRoute,
  FinancesInvoiceFormRoute: FinancesInvoiceFormRoute,
  LibraryExternalArticlesRoute: LibraryExternalArticlesRoute,
  LibraryExternalResourcesRoute: LibraryExternalResourcesRoute,
  ScheduleLessonIdRoute: ScheduleLessonIdRoute,
  AnnouncesIndexRoute: AnnouncesIndexRoute,
  EducationIndexRoute: EducationIndexRoute,
  FinancesIndexRoute: FinancesIndexRoute,
  LibraryIndexRoute: LibraryIndexRoute,
  PortfolioIndexRoute: PortfolioIndexRoute,
  ScheduleIndexRoute: ScheduleIndexRoute,
  EducationDisciplineIdMaterialsRoute: EducationDisciplineIdMaterialsRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/pass",
        "/service",
        "/announces/$announceId",
        "/education/$disciplineId",
        "/finances/invoice-form",
        "/library/external-articles",
        "/library/external-resources",
        "/schedule/$lessonId",
        "/announces/",
        "/education/",
        "/finances/",
        "/library/",
        "/portfolio/",
        "/schedule/",
        "/education_/$disciplineId/materials"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/pass": {
      "filePath": "pass.tsx"
    },
    "/service": {
      "filePath": "service.tsx"
    },
    "/announces/$announceId": {
      "filePath": "announces/$announceId.tsx"
    },
    "/education/$disciplineId": {
      "filePath": "education/$disciplineId.tsx"
    },
    "/finances/invoice-form": {
      "filePath": "finances/invoice-form.tsx"
    },
    "/library/external-articles": {
      "filePath": "library/external-articles.tsx"
    },
    "/library/external-resources": {
      "filePath": "library/external-resources.tsx"
    },
    "/schedule/$lessonId": {
      "filePath": "schedule/$lessonId.tsx"
    },
    "/announces/": {
      "filePath": "announces/index.tsx"
    },
    "/education/": {
      "filePath": "education/index.tsx"
    },
    "/finances/": {
      "filePath": "finances/index.tsx"
    },
    "/library/": {
      "filePath": "library/index.tsx"
    },
    "/portfolio/": {
      "filePath": "portfolio/index.tsx"
    },
    "/schedule/": {
      "filePath": "schedule/index.tsx"
    },
    "/education_/$disciplineId/materials": {
      "filePath": "education_/$disciplineId/materials.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
