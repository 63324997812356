import { createStyles } from 'antd-style';

import { cyan, geekblue, magenta, orange } from '@ant-design/colors';

export const useStyles = createStyles(({ css, token, prefixCls }) => ({
  name: css`
    font-size: ${token.fontSizeLG}px;
    font-weight: ${token.fontWeightStrong};

    &.${prefixCls}-typography {
      margin-bottom: 0;
    }
  `,
  card: css`
    width: 320px;
    height: 139px;
  `,
  blue: css`
    color: ${geekblue[5]};
    background-color: ${geekblue[0]};
  `,
  magenta: css`
    color: ${magenta[5]};
    background-color: ${magenta[0]};
  `,
  cyan: css`
    color: ${cyan[5]};
    background-color: ${cyan[0]};
  `,
  orange: css`
    color: ${orange[5]};
    background-color: ${orange[0]};
  `,
  tag: css`
    border-radius: 100px;
  `,
  w100: css`
    width: 100%;
  `,
}));
