import { useSuspenseQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { Button, Flex, Layout, Typography } from 'antd';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CalendarFilled, CloseOutlined } from '@ant-design/icons';

import { Calendar, DayItem } from './components';
import { useStyles } from './styles.ts';
import { getTitleBySelectedDate } from './utils';

import { scheduleQueries } from '@/api/endpoints/schedule';

import { ScheduleLesson } from '@/components/ui/lesson';
import { NoContent } from '@/components/ui/no-content';

import { dateFormats } from '@/configs';

import { getLessonsWord } from '@/utils';

import 'swiper/css';

dayjs.extend(dayLocaleData);

const scheduleRouteApi = getRouteApi('/schedule/');

export const SchedulePage = () => {
  const { styles, cx } = useStyles();

  const { selectedDate } = scheduleRouteApi.useSearch();
  const navigate = scheduleRouteApi.useNavigate();
  const {
    data: { current, nextDays, prevDays },
  } = useSuspenseQuery(scheduleQueries.byDate(selectedDate));

  const todayDate = useRef(dayjs().format(dateFormats.default));
  const swiperRef = useRef<null | SwiperInstance>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const todayIsSelectedDate = selectedDate === todayDate.current;
  const onClose = () => setIsDrawerOpen(false);
  const selectNewDate = (date: string) => {
    navigate({
      search: { selectedDate: date },
    });
  };

  useEffect(() => {
    if (swiperRef.current) {
      // Сброс свайпера при выборе новой даты
      // Индекс 4 - хардкод, поскольку в свайпере 15 элементов и чтобы 8 был +- посередине экрана, надо выставить индекс 4
      swiperRef.current.slideTo(4, 300);
    }
  }, [selectedDate]);

  return (
    <>
      <Layout.Content className='content'>
        <Flex vertical gap={24}>
          <Flex align='center' justify='space-between' className={'p-x'}>
            <Flex vertical gap={8}>
              <Typography.Title level={4}>
                {getTitleBySelectedDate(selectedDate, todayDate.current)}
              </Typography.Title>
              <Typography.Text type='secondary'>
                {current.length} {getLessonsWord(current.length)}
              </Typography.Text>
            </Flex>

            <Flex gap={8}>
              {!todayIsSelectedDate && (
                <Button
                  size='large'
                  onClick={() => selectNewDate(todayDate.current)}
                  icon={<CloseOutlined />}
                />
              )}
              <Button
                type={todayIsSelectedDate ? 'default' : 'primary'}
                size='large'
                icon={<CalendarFilled />}
                onClick={() => setIsDrawerOpen(true)}
              />
            </Flex>
          </Flex>

          <Swiper
            grabCursor
            slidesPerView='auto'
            className={cx(styles.swiper, 'p-x')}
            spaceBetween={4}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {prevDays.map((day) => (
              <SwiperSlide className={styles.swiperSlide} key={day.date}>
                <DayItem day={day} onClick={() => selectNewDate(day.date)} />
              </SwiperSlide>
            ))}
            <SwiperSlide className={styles.swiperSlide}>
              <DayItem
                day={{
                  date: selectedDate,
                  exam: null,
                }}
                selected
              />
            </SwiperSlide>
            {nextDays.map((day) => (
              <SwiperSlide className={styles.swiperSlide} key={day.date}>
                <DayItem day={day} onClick={() => selectNewDate(day.date)} />
              </SwiperSlide>
            ))}
          </Swiper>

          <Flex vertical gap={8} className={'p-x'}>
            {current.length > 0 ? (
              current.map((lesson) => (
                <ScheduleLesson lesson={lesson} key={lesson.id} />
              ))
            ) : (
              <NoContent
                title='Занятий нет'
                description='А пока можете посмотреть расписание на другие дни'
              />
            )}
          </Flex>
        </Flex>
      </Layout.Content>

      <Calendar
        onDateSelect={(date: string) => selectNewDate(date)}
        onClose={onClose}
        isDrawerOpen={isDrawerOpen}
        selectedDate={selectedDate}
      />
    </>
  );
};
