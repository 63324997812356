import { queryOptions } from '@tanstack/react-query';

import { httpWrapper } from '@/api/client';

export type TScheduleLessonDTO = {
  id: number;
  discipline: string;
  teacher: string;
  online: boolean;
  route: string;
  period: string;
  exam: 0 | 1;
  cancel: 0 | 1;
  title?: string;
};
export type TScheduleDayDTO = {
  date: string;
  exam: null | 1 | 0;
};
export type TScheduleSemesterDTO = {
  start: string;
  finish: string;
};
type TScheduleByDateDTO = {
  prevDays: TScheduleDayDTO[];
  nextDays: TScheduleDayDTO[];
  current: TScheduleLessonDTO[];
};
const fetchScheduleToday = () => {
  return httpWrapper.get<TScheduleLessonDTO[]>('widgets/schedule');
};
const fetchLesson = (lessonId: string) => {
  return httpWrapper.get<TScheduleLessonDTO>(`schedule/${lessonId}`);
};
const fetchScheduleByDate = (date: string) => {
  return httpWrapper.get<TScheduleByDateDTO>('schedule?date=' + date);
};
const fetchScheduleMonth = (month: number, year: number) => {
  return httpWrapper.get<TScheduleDayDTO[]>(
    `schedule?month=${month}&year=${year}`,
  );
};
const fetchScheduleSemester = () => {
  return httpWrapper.get<TScheduleSemesterDTO>('semester');
};

export const scheduleQueries = {
  today: () =>
    queryOptions({
      queryKey: ['schedule', 'today'],
      queryFn: fetchScheduleToday,
    }),
  lesson: (lessonId: string) =>
    queryOptions({
      queryKey: ['schedule', 'lesson', lessonId],
      queryFn: () => fetchLesson(lessonId),
    }),
  byDate: (date: string) =>
    queryOptions({
      queryKey: ['schedule', 'date', date],
      queryFn: () => fetchScheduleByDate(date),
    }),
  month: (month: number, year: number) =>
    queryOptions({
      queryKey: ['schedule', 'month', { month, year }],
      queryFn: () => fetchScheduleMonth(month, year),
    }),
  semester: () =>
    queryOptions({
      queryKey: ['schedule', 'semester'],
      queryFn: fetchScheduleSemester,
      staleTime: Infinity,
    }),
};
