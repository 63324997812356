import { createFileRoute } from '@tanstack/react-router';

import {
  InvoiceFormPage,
  InvoiceFormPending,
} from '@/pages/finances/invoice-form';

export const Route = createFileRoute('/finances/invoice-form')({
  component: InvoiceFormPage,
  pendingComponent: InvoiceFormPending,
});
