import { Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';

import { useStyles } from '../styles.ts';

import { TScheduleDayDTO } from '@/api/endpoints/schedule';

import { capitalizeFirstLetter } from '@/utils';

export const DayItem = ({
  day,
  selected,
  onClick,
}: {
  day: TScheduleDayDTO;
  selected?: boolean;
  onClick?: () => void;
}) => {
  const { styles, cx } = useStyles();
  const fullDate = dayjs(day.date, 'DD.MM.YYYY');

  return (
    <Flex vertical align='center' className={styles.day} gap={4}>
      <Typography.Text>
        {capitalizeFirstLetter(fullDate.format('dd'))}
      </Typography.Text>
      <Button
        size='large'
        shape='round'
        className={cx(styles.btn, {
          [styles.blueBtn]: day.exam === 0,
          [styles.greenBtn]: day.exam === 1,
        })}
        disabled={day.exam === null && !selected}
        variant={selected ? 'solid' : 'outlined'}
        color={selected ? 'primary' : undefined}
        type={selected ? 'primary' : 'default'}
        onClick={onClick}
      >
        {fullDate.format('DD')}
      </Button>
    </Flex>
  );
};
