import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import Cookies from 'js-cookie';
import { create } from 'zustand';

import { getThemeConfig } from './config.ts';
import { getInitialTheme } from './lib/getInitialTheme.ts';
import { updateFavicon } from './lib/updateFavicon.ts';
import { updateMeta } from './lib/updateMeta.ts';
import { updateStylesFile } from './lib/updateStylesFile.ts';
import { EThemes } from './types.ts';

import { EStorageKeys } from '@/configs';

type TState = {
  theme: EThemes;
};

type TAction = {
  updateTheme: (theme: EThemes) => void;
};

export type TThemeStore = TState & TAction;

const useThemeStoreBase = create<TThemeStore>()((set) => {
  const theme = getInitialTheme();

  const updateTheme = (theme: EThemes) => {
    set(() => ({ theme }));
    updateFavicon(theme);
    updateMeta(theme);
    updateStylesFile(theme);
    Cookies.set(EStorageKeys.THEME, theme);
  };

  updateTheme(theme);

  return {
    theme,
    updateTheme,
  };
});

export const useThemeStore = createSelectorFunctions(useThemeStoreBase);

export const useThemeConfig = () => {
  const theme = useThemeStore.use.theme();
  return getThemeConfig(theme);
};
