import { Button, Col, Flex, Row, Select, Typography } from 'antd';
import { Dayjs } from 'dayjs';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { useSemesterBoundaries } from '../hooks';

import { capitalizeFirstLetter } from '@/utils';

type TCalendarHeaderProps = {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
};

export const CalendarHeader = ({ value, onChange }: TCalendarHeaderProps) => {
  const { styles, cx } = useStyles();

  const {
    yearOptions,
    monthsOptions,
    selectedDateYear,
    selectedDateMonth,
    semesterEnd,
    semesterStart,
    previousMonthName,
    nextMonthName,
  } = useSemesterBoundaries(value);

  const isPrevMonthDisabled =
    value.isBefore(semesterStart, 'month') ||
    value.isSame(semesterStart, 'month');

  const isNextMonthDisabled =
    value.isAfter(semesterEnd, 'month') || value.isSame(semesterEnd, 'month');

  const onPrevMonthClick = () => {
    if (!isPrevMonthDisabled) {
      const newMonthValue = value.clone().subtract(1, 'month');
      onChange(newMonthValue);
    }
  };
  const onNextMonthClick = () => {
    if (!isNextMonthDisabled) {
      const newMonthValue = value.clone().add(1, 'month');
      onChange(newMonthValue);
    }
  };

  const handleYearChange = (newYear: number) => {
    let now = value.clone().year(newYear);

    if (
      newYear === semesterStart.year() &&
      now.month() < semesterStart.month()
    ) {
      now = now.month(semesterStart.month());
    } else if (
      newYear === semesterEnd.year() &&
      now.month() > semesterEnd.month()
    ) {
      now = now.month(semesterEnd.month());
    }

    onChange(now);
  };

  const handleMonthChange = (newMonth: number) => {
    const now = value.clone().month(newMonth);
    onChange(now);
  };

  return (
    <>
      <Row gutter={8} className={styles.header}>
        <Col span={12}>
          <Select
            size='small'
            value={selectedDateYear}
            onChange={handleYearChange}
            rootClassName={styles.select}
            options={yearOptions}
          />
        </Col>
        <Col span={12}>
          <Select
            size='small'
            value={selectedDateMonth}
            onChange={handleMonthChange}
            rootClassName={styles.select}
            options={monthsOptions}
          />
        </Col>
      </Row>

      <Flex vertical gap={12} className={styles.legend}>
        <Flex gap={12} align='center'>
          <span className={cx(styles.legendItem, styles.blue)}>1</span>
          <Typography.Text>Дополнительные события</Typography.Text>
        </Flex>
        <Flex gap={12} align='center'>
          <span className={cx(styles.legendItem, styles.green)}>1</span>
          <Typography.Text>События учебного процесса</Typography.Text>
        </Flex>
      </Flex>

      <Row gutter={8} className={styles.pagination}>
        <Col span={12}>
          <Button
            onClick={onPrevMonthClick}
            block
            disabled={isPrevMonthDisabled}
            icon={<ArrowLeftOutlined />}
          >
            {capitalizeFirstLetter(previousMonthName)}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={onNextMonthClick}
            block
            icon={<ArrowRightOutlined />}
            iconPosition='end'
            disabled={isNextMonthDisabled}
          >
            {capitalizeFirstLetter(nextMonthName)}
          </Button>
        </Col>
      </Row>
    </>
  );
};
