import { Flex, Layout, Skeleton } from 'antd';

export const DisciplineMaterialsPending = () => {
  return (
    <Layout className='content p-x'>
      <Flex vertical gap={48}>
        <Flex vertical gap={24}>
          <Skeleton.Button active shape='round' />
          <Skeleton.Input size='large' block active />
        </Flex>

        <Flex vertical gap={8}>
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <Skeleton.Input active key={i} block />
            ))}
        </Flex>
      </Flex>
    </Layout>
  );
};
