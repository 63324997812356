import { Flex, Typography } from 'antd';

import { AnnounceTypeTag } from './AnnounceTypeTag.tsx';
import { useStyles } from './styles.ts';

import { TAnnounceItemDTO } from '@/api/endpoints/announce';

import { dateFormats } from '@/configs';

import { dayjsDefault } from '@/utils';

type TAnnounceContentProps = {
  announce: TAnnounceItemDTO;
};

export const AnnounceContent = ({ announce }: TAnnounceContentProps) => {
  const { styles } = useStyles();

  return (
    <Flex vertical gap={8} className={styles.w100}>
      <Flex justify='space-between'>
        <Typography.Text type='secondary'>
          от {dayjsDefault(announce.datetime).format(dateFormats.dayMonthYear)}
        </Typography.Text>

        {announce.type && <AnnounceTypeTag type={announce.type} />}
      </Flex>
      <Typography.Paragraph className={styles.name} ellipsis={{ rows: 3 }}>
        {announce.title}
      </Typography.Paragraph>
    </Flex>
  );
};
