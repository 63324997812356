import { Link } from '@tanstack/react-router';
import { Flex, Typography } from 'antd';
import { ReactNode } from 'react';

import { useStyles } from './styles.ts';

type TMenuLabelProps = {
  icon: ReactNode;
  description: string;
  disabled?: boolean;
  link: string;
};

export const MenuLabel = ({
  icon,
  description,
  link,
  disabled,
}: TMenuLabelProps) => {
  const { styles } = useStyles();

  return (
    <Link to={link} disabled={disabled} preload='render'>
      <Flex vertical align='center' className={styles.label}>
        {icon}
        <Typography.Text className={styles.moduleDescription}>
          {description}
        </Typography.Text>
      </Flex>
    </Link>
  );
};
