import dayjs, { Dayjs } from 'dayjs';

import { TScheduleDayDTO } from '@/api/endpoints/schedule';

export const checkDateDisabled = (
  dayjsDate: Dayjs,
  activeDays: TScheduleDayDTO[] | undefined,
) => {
  if (!activeDays) return false;

  const isToday = dayjs().isSame(dayjsDate, 'day');
  const hasEvent = activeDays.some(
    (date) => date.date === dayjsDate.format('DD.MM.YYYY'),
  );

  return !hasEvent && !isToday;
};
